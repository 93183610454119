import React from "react"
import PropTypes from "prop-types"
import { graphql } from "gatsby"
import { Layout, Section, Typography, Link } from "../components"

const ConfirmationPage = ({ data }) => (
  <Layout>
    <Section
      variant="image-right"
      image={
        data.image.childImageSharp ? data.image.childImageSharp.fluid : null
      }
    >
      <br />
      <br />
      <br />
      <br />
      <Typography variant="large">Votre message a été envoyé&nbsp;!</Typography>
      <Typography>
        Nous vous recontacterons dans les plus brefs délais.
      </Typography>
      <Link to="/">Retourner sur la page d&apos;accueil</Link>
      <br />
      <br />
      <br />
      <br />
    </Section>
  </Layout>
)

ConfirmationPage.propTypes = {
  data: PropTypes.object,
}
export default ConfirmationPage

export const query = graphql`
  query {
    image: file(relativePath: { eq: "office.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 400, maxHeight: 250) {
          ...GatsbyImageSharpFluid
        }
      }
    }
  }
`
